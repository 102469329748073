<template>
  <video src="../../assets/backgroundVid.mp4" autoplay muted></video>
  <div class="mainLoginDiv">
    <vue-notification-list class="notification" position="top-right"></vue-notification-list>
    <div class="spanAdjust">
      <span class="titolo">Sgamba<span class="titolo2">reo</span></span>
    </div>

    <div class="forms" :class="registerRotate">
      <transition name="fade">
        <div class="login-frame" v-if="loginSwitch">
          <label for="username" style="color: var(--marrone)">email</label>
          <input type="email" v-model="email_tmp" @keydown.enter="login" />
          <label for="password" style="color: var(--marrone)">password</label>
          <input type="password" v-model="password_tmp" @keydown.enter="login" />
          <div class="buttons">
            <base-button size="small" @click="login">LOGIN</base-button>
            <base-button size="small" @click="gotoRegistration">REGISTRATION</base-button>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="register-frame" v-if="registerSwitch">
          <label for="email" style="color: var(--marrone)">choose an email</label>
          <input type="email" v-model="email_tmp" @keydown.enter="register" />
          <label for="username" style="color: var(--marrone)">choose a username</label>
          <input type="text" v-model="username_tmp" @keydown.enter="register" />
          <label for="password" style="color: var(--marrone)">choose a password</label>
          <input type="password" v-model="password_tmp" @keydown.enter="register" />
          <div class="buttons">
            <base-button size="small" @click="gotoLogin">BACK TO LOGIN</base-button>
            <base-button size="small" @click="register">REGISTER</base-button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { useNotificationStore } from "@dafcoe/vue-notification";

export default {
  data() {
    return {
      username_tmp: "",
      password_tmp: "",
      email_tmp: "",
      username: "",
      password: "",
      email: "",
      registerRotate: "",
      loginSwitch: true,
      registerSwitch: false,
    };
  },

  methods: {
    login() {
      this.email = this.email_tmp;
      this.email_tmp = "";
      this.password = this.password_tmp;
      this.password_tmp = "";
      //let session_token = ""
      const data = {
        email: this.email,
        password: this.password,
      };

      this.axios
        .post(`${this.$store.getters.getTunnel}/api/auth/login`, data)
        .then((loginResponse) => {
          if (loginResponse.data != 0) {
            localStorage.setItem("bearerToken", "Bearer " + loginResponse.data[0]["original"]["access_token"]);

            this.$router.push(`/lobby/${loginResponse.data[1].id}/${loginResponse.data[1].id}`);
          } else {
            console.log("Cannot login :(");
          }
        })

        .catch((error) => {
          console.log(error);
        })
        // })
        .catch((error) => {
          console.error(error);
        });
    },
    register() {
      this.username = this.username_tmp;
      this.username_tmp = "";
      this.password = this.password_tmp;
      this.password_tmp = "";
      this.email = this.email_tmp;
      this.email_tmp = "";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const data = {
        username: this.username,
        password: this.password,
        email: this.email,
      };
      this.axios
        .post(`${this.$store.getters.getTunnel}/api/register`, JSON.stringify(data), { headers: headers })
        .then((response) => {
          const { setNotification } = useNotificationStore();
          const registeredNotification = {
            message: response.data,
            type: "success",
            showIcon: true,
            dismiss: {
              manually: true,
              automatically: true,
            },
            duration: 10000,
            showDurationProgress: true,
            appearance: "glass",
          };
          setNotification(registeredNotification);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    gotoLogin() {
      this.switcher("loginSwitch");
      this.rotate();
    },
    gotoRegistration() {
      this.switcher("registerSwitch");
      this.rotate();
    },
    rotate() {
      if (this.registerRotate === "") {
        this.registerRotate = "rotate180";
      } else {
        this.registerRotate = this.registerRotate === "rotate-180" ? "rotate180" : "rotate-180";
      }
    },
    switcher(first) {
      if (first === "loginSwitch") {
        this.registerSwitch = false;
        setTimeout(() => {
          console.log("lateswitch");
          this.loginSwitch = true;
        }, 1000);
      } else if (first === "registerSwitch") {
        this.loginSwitch = false;
        setTimeout(() => {
          console.log("formswitch");

          this.registerSwitch = true;
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
.mainLoginDiv {
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.forms {
  margin: 10px;
  width: 20%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  box-shadow: 0 0 10px var(--marrone);
}
.forms::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px var(--marrone);
  z-index: -1;
}

.rotate180 {
  animation: rotation 1s linear;
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
@keyframes rotationBack {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
video {
  width: 100%;
  height: 100vh;
  position: absolute;
  object-fit: fill;
}
input {
  width: 90%;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px 10px;
  margin-bottom: 20px;
}
.titolo {
  font-weight: 670;
  font-size: 68px;
  color: var(--marrone);
  animation: fadeIn 11s ease-in;
}
.titolo2 {
  color: var(--giallo-chiaro);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.rotate-180 {
  animation: rotationBack 1s linear;
}

.spanAdjust {
  width: 420px;
  height: 20vh;
  display: flex;
  justify-content: flex-end;
}
.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.notification {
  position: absolute;
}
.login-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.register-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}
</style>
