<template>
  <div class="mainNavBar">
    <div class="leftNavBar">
      <div class="userName">Logged in as: {{ player() }}</div>
      <base-button size="small" @click="logout">Logout</base-button>
    </div>
    <div class="rightNavBar">
      <h2>Turn number: {{ this.$store.state.currentTurn }}</h2>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sendUser: "",
      playerName: "",
    };
  },
  mounted() {
    this.sendUser = this.$store.getters.getUser;
  },

  methods: {
    player() {
      this.axios.post(`${this.$store.getters.getTunnel}/api/auth/me`).then((response) => {
        this.playerName = response.data.name;
      });
      return this.playerName;
    },
    logout() {
      this.axios.post(`${this.$store.getters.getTunnel}/api/auth/logout`).then((response) => {
        console.log(response);
        localStorage.setItem("bearerToken", "");
        this.$router.push("/");
      });
    },
  },
};
</script>
<style scoped>
.mainNavBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4vh;
  background-color: rgb(255, 166, 0);
}
.rightNavBar {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftNavBar {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 25px;
}
.userName {
  margin-right: 20px;
  margin-left: 10px;
}
</style>
