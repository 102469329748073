export class letter {
  constructor(char, posx, posy, owner, turn, value) {
    this.char = char;
    this.posx = posx;
    this.posy = posy;
    this.owner = owner;
    this.turn = turn;
    this.value = value;
  }
}
