<template>
  <div :class="['centerDiv', position]">
    <div v-for="(user, i) in this.$store.state.currentLobby" :key="i" class="lobbyUser">{{ user.name }}</div>
  </div>
</template>
<script>
export default {
  props: ["position"],
  data() {
    return {}
  },
}
</script>
<style>
.centerDiv {
  width: 90%;

  margin-top: 30px;
  background-color: var(--giallo-chiaro);
  border: 3px solid var(--marrone);
  box-shadow: 2cm var(--giallo-chiaro);
  padding: 20px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.lobbyUser {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  padding: 5px;
  border: 3px solid var(--marrone);
}
.inLobby {
  height: 600px;
}
.inGame {
  padding: 10px;
  width: 185px;
  background-color: var(--giallo-chiaro);
  height: 300px;
}
</style>
