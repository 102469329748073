<template>
  <div class="mainWindow">
    <TheNavbar></TheNavbar>
    <GameFrame></GameFrame>
  </div>
</template>
<script>
import GameFrame from "./GameFrame.vue";
import TheNavbar from "./TheNavbar.vue";
export default {
  components: {
    TheNavbar,
    GameFrame,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.mainWindow {
  width: 100%;
}
</style>
