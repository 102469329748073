import { createApp } from "vue"
import App from "./App.vue"
import { router } from "./router"
import { store } from "./store"
import VueDragscroll from "vue-dragscroll"
import BaseButton from "./components/BaseComps/BaseButton.vue"
import BaseLobby from "./components/BaseComps/BaseLobby.vue"
import VueNotificationList from "@dafcoe/vue-notification"
import "@dafcoe/vue-notification/dist/vue-notification.css"
import { ax } from "./httpHelper.js"
import Echo from "laravel-echo"
import Pusher from "pusher-js"

window.Pusher = Pusher
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "f4c8e62b59b28d581b09",
  cluster: "eu",
  encrypted: true,
  auth: {
    headers: {
      Authorization: localStorage.getItem("bearerToken"),
    },
  },
})

const app = createApp(App).use(store).use(router).use(VueDragscroll).use(VueNotificationList).component("base-lobby", BaseLobby).component("base-button", BaseButton)

window.addEventListener("beforeunload", function () {
  const bearerToken = localStorage.getItem("bearerToken")

  const options = {
    method: "POST",
    headers: {
      Authorization: bearerToken,
    },
    keepalive: true,
  }

  fetch(`${store.getters.getTunnel}/api/auth/logout`, options)
  //fetch("https://a73a-151-15-25-0.eu.ngrok.io/api/auth/logout", options);
})

app.config.globalProperties.axios = ax //this instance has specified interceptors for interaction with
app.config.globalProperties.echo = window.Echo
//app.config.globalProperties.axios = axios //this instance is fully customizable for interaction with third-party services

app.mount("#app")
