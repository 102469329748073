import { createRouter, createWebHistory } from "vue-router";
import loginScreen from "./components/MainComps/LoginScreen.vue";
import mainScreen from "./components/MainComps/MainScreen.vue";
import lobby from "./components/MainComps/Lobby.vue";
const routes = [
  {
    path: "/main/:lobbyOwner/:lobbyCode",
    component: mainScreen,
  },
  {
    path: "/",
    component: loginScreen,
  },
  {
    path: "/lobby/:lobbyOwner/:lobbyCode",
    component: lobby,
  },
];
export const router = createRouter({
  history: createWebHistory(),
  routes,
});
