<template>
  <div class="mainGameFrame">
    <!-- <div class="lettersDiv">
      <div class="cover">
        <div class="letters">
          <div class="cols" v-for="(row, i) in chosenLetters" :key="i">
            <div class="letter" v-for="(letter, j) in row" :key="j">{{ letter }}</div>
          </div>
        </div>
      </div>
    </div> -->
    <vue-notification-list class="notification" position="top-right"></vue-notification-list>
    <div ref="game" v-dragscroll class="game">
      <div class="grid-item" v-for="(column, i) in gridArray" :key="i">
        <div class="grid-cell" :class="cellColor(i, j)" v-for="(cell, j) in column" :key="cell" @click="updateCell(i, j)">
          {{ cell.char }}
        </div>
      </div>
    </div>
    <div class="sidebar">
      <div class="players">
        <base-lobby position="inGame"></base-lobby>
      </div>

      <div class="letters">
        <!-- <div class="cols" v-for="(row, i) in chosenLetters" :key="i"> -->
        <div class="letter" v-for="(lett, i) in chosenLetters" :key="i" @click="pickLetter(i)">{{ lett.char }}</div>
        <!-- </div> -->
      </div>
      <div class="proceedButton">
        <base-button size="small" :class="disabledClass" @click="submitWord()"> Submit Word </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import { letter } from "../../classes/letter.js";
import { useNotificationStore } from "@dafcoe/vue-notification";

export default {
  computed: {
    disabledClass() {
      if (this.isDisabled) {
        return "disabled";
      }
      return "";
    },
  },
  created() {
    this.echo.channel("game-channel" + this.$route.params.lobbyCode).listen(".game-event", (e) => {
      this.gridArray[e.posX][e.posY] = new letter(e.char, e.posX, e.posY, null, null);
    });
  },
  mounted() {
    this.$refs.game.style.zoom = 1.6;
    this.createArray();
  },
  data() {
    return {
      isDisabled: true,
      pusher: null,
      channel: null,
      zoomLevel: 1.6,
      gridArray: Array.from({ length: 17 }, () => Array(17).fill(new letter(""))),
      chosenLetters: Array.from({ length: 4 }, () => Array(2).fill(new letter("", null, null, this.$route.params.lobbyOwner, null))),
    };
  },
  methods: {
    submitWord() {
      this.$store.state.currentTurn++;
      this.isDisabled = true;
      this.createArray();
    },
    handleZoom(event) {
      //@wheel.passive="handleZoom"
      event.preventDefault;
      if (event.deltaY > 0 && this.zoomLevel < 3) {
        this.zoomLevel = this.zoomLevel + 0.1;
      } else if (event.deltaY < 0 && this.zoomLevel > 1.6) {
        this.zoomLevel = this.zoomLevel - 0.1;
      }
      this.$refs.game.style.zoom = this.zoomLevel;
      console.log(this.$refs.game.style.zoom);
    },
    updateCell(i, j) {
      if (this.gridArray[i][j].char === "" && this.$store.getters.getLetter !== null) {
        this.gridArray[i][j] = new letter(this.$store.getters.getLetter, null, null, this.$route.params.lobbyOwner, this.$store.state.currentTurn, null);
        //non so perché non funziona cambiare solo il .char
        this.$store.commit("setLetter", null);
        this.checkWord(i, j);
      } else {
        for (let n in this.chosenLetters) {
          console.log(this.gridArray[i][j]);
          console.log(this.$store.state.currentTurn);
          if (this.chosenLetters[n].char === "" && this.gridArray[i][j].turn === this.$store.state.currentTurn && this.gridArray[i][j].turn !== null) {
            this.chosenLetters[n] = this.gridArray[i][j];
            this.gridArray[i][j] = new letter("");

            break;
          }
        }
      }
    },
    createArray() {
      this.axios.post(`${this.$store.getters.getTunnel}/api/getLetters`).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          this.chosenLetters[i] = new letter(response.data[i].letter, null, null, this.$route.params.lobbyOwner, null);
        }
      });
    },
    pickLetter(i) {
      if (this.$store.getters.getLetter === null) {
        this.$store.commit("setLetter", this.chosenLetters[i].char);

        this.chosenLetters[i] = new letter("");
      }
    },
    checkWord(i, j) {
      //verifica verticale
      let wordY = "";
      let wordX = "";
      let letterI = i;

      while (this.gridArray[letterI][j].char !== "" && letterI > 0) {
        letterI--;
      }
      do {
        letterI++;
        wordY += this.gridArray[letterI][j].char;
      } while (this.gridArray[letterI][j].char !== "" && letterI < 16);

      //verifica orizzontale
      let letterJ = j;

      while (this.gridArray[i][letterJ].char !== "" && letterJ > 0) {
        letterJ--;
      }
      do {
        letterJ++;
        wordX += this.gridArray[i][letterJ].char;
      } while (this.gridArray[i][letterJ].char !== "" && letterJ < 16);

      console.log(wordX, wordY);
      const headers = {
        "Content-Type": "application/json",
      };
      //spediamo al backend per Y
      if (wordX.length === wordY.length) {
        let data = {
          word: wordY,
        };

        this.axios.post(`${this.$store.getters.getTunnel}/api/in`, data, headers).then((response) => {
          if (response.data === 1) {
            if (this.$store.state.currentTurn != 0) {
              this.isDisabled = false;
            } else if (this.gridArray[8][8].char === "") {
              const { setNotification } = useNotificationStore();
              const registeredNotification = {
                message: "La parola deve coinvolgere la casella centrale",
                type: "alert",
                showIcon: true,
                dismiss: {
                  manually: true,
                  automatically: true,
                },
                duration: 10000,
                showDurationProgress: true,
                appearance: "glass",
              };
              setNotification(registeredNotification);
            }
          } else {
            this.isDisabled = false;
          }
        });
      } else if (wordX.length > wordY.length) {
        let data = {
          word: wordY,
        };

        this.axios.post(`${this.$store.getters.getTunnel}/api/in`, data, headers).then((response) => {
          console.log(response.data);
          if (response.data === 1) {
            if (this.$store.state.currentTurn != 0) {
              this.isDisabled = false;
            } else if (this.gridArray[8][8].char === "") {
              const { setNotification } = useNotificationStore();
              const registeredNotification = {
                message: "La parola deve coinvolgere la casella centrale",
                type: "alert",
                showIcon: true,
                dismiss: {
                  manually: true,
                  automatically: true,
                },
                duration: 10000,
                showDurationProgress: true,
                appearance: "glass",
              };
              setNotification(registeredNotification);
            }
          } else {
            this.isDisabled = false;
          }
        });
      } else if (wordY.length > wordX.length) {
        let data = {
          word: wordX,
        };

        this.axios.post(`${this.$store.getters.getTunnel}/api/in`, data, headers).then((response) => {
          console.log(response.data);

          if (response.data === 1) {
            if (this.$store.state.currentTurn != 0) {
              this.isDisabled = false;
            } else if (this.gridArray[8][8].char === "") {
              const { setNotification } = useNotificationStore();
              const registeredNotification = {
                message: "La parola deve coinvolgere la casella centrale",
                type: "alert",
                showIcon: true,
                dismiss: {
                  manually: true,
                  automatically: true,
                },
                duration: 10000,
                showDurationProgress: true,
                appearance: "glass",
              };
              setNotification(registeredNotification);
            }
          } else {
            this.isDisabled = false;
          }
        });
      }
      let data = {
        char: this.gridArray[i][j].char,
        posx: i,
        posy: j,
      };

      this.axios.post(`${this.$store.getters.getTunnel}/api/updateClients`, data, headers).then(() => {});
    },
    cellColor(i, j) {
      if (i == j) {
        //sei sulla diagonale principale
        if (i == 8) {
          return "starterCell";
        }
        if (j == 7 || j == 9) {
          return "x2";
        }
        if (j == 0 || j == 16) {
          return "x3w";
        }

        return "x2w";
      } else if (j + i == 16) {
        //sei sull'altra diagonale
        if (j == 7 || j == 9) {
          return "x2";
        }
        if (j == 0 || j == 16) {
          return "x3w";
        }
        return "x2w";
      }
      if (i == 8 && (j == 0 || j == 16)) {
        return "x3w";
      }
      if (j == 8 && (i == 0 || i == 16)) {
        return "x3w";
      }
    },
    stopMatch() {
      this.axios.post(`${this.$store.getters.getTunnel}/api/destroyAlphabetMatchTable/${this.$route.params.lobbyOwner}`).then((response) => {
        console.log(response);
      });
    },
  },
};
</script>
<style scoped>
.mainGameFrame {
  width: 100%;
  height: 96vh;
  background-color: var(--giallo-chiaro);
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.game {
  width: 80%;

  overflow: scroll;
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  z-index: 3;
}
.players {
  height: 40%;
}

.grid-cell,
.letter {
  border: 1px solid var(--verde-scuro);
  width: 100%;

  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.letter {
  width: 70px;
  margin-bottom: 5px;
  z-index: 103;
  background-color: white;
}
.letter:hover,
.grid-cell:hover {
  background-color: rgba(211, 134, 10, 0.533);
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.sidebar {
  width: 15%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 20px;
}
.letters {
  width: 100%;
  height: 40%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  z-index: 4;
}
.x2 {
  background-color: var(--verde-chiaro);
  color: red;
}
.x3 {
  background-color: rgba(180, 2, 2, 0.697);
  color: black;
}

.x2w {
  background-color: var(--marrone-chiaro);
  color: black;
}
.x3w {
  background-color: var(--arancione-scuro);
  color: black;
}
.starterCell {
  background-color: var(--verde-scuro) !important;
}
.lettersDiv {
  width: 100%;
  height: 90%;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;
}
.cover {
  width: 30%;
  height: 100px;
  background-color: rgba(141, 155, 221, 0.3);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.proceedButton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
