<template>
  <button :class="[size, isDisabled]" :disabled="disabledValue">
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: ["size", "isDisabled"],

  computed: {
    disabledValue() {
      if (this.isDisabled === undefined || this.isDisabled === "") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
button {
  background: linear-gradient(90deg, var(--arancione-chiaro), var(--beige));
  box-shadow: 3px lightpink;
  border: 1px solid;
  color: var(--marrone);
}
button:active {
  font-size: small;
  /* transform: scale(0.95); */
}
button:hover {
  background: linear-gradient(160deg, var(--giallo-chiaro), var(--marrone-chiaro));
}

.mini {
  font-weight: 500;
  font-size: 15px;
  width: 70px;
  height: 20px;
  border-radius: 7px;
}
.small {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 30px;
  border-radius: 10px;
}
.wide {
  font-weight: 600;
  font-size: 25px;
  width: 170px;
  height: 40px;
  border-radius: 20px;
}

.plus {
  font-weight: 700;
  font-size: 100%;
  border-radius: 5px;
  padding: 5% 10%;
}
.disabled {
  opacity: 0.6 !important;
}
</style>
