import vuex from "vuex";
export const store = new vuex.Store({
  state: {
    loggedUser: "",
    currentLetter: null,
    matchStatus: 0,
    ngrok: "https://6d72-151-57-213-208.eu.ngrok.io",
    //ngrok: "http://localhost:8000",
    currentLobby: [],
    currentTurn: 0,
  },
  mutations: {
    setLobby: (state, arr) => {
      state.currentLobby = arr;
    },
    setLetter: (state, letter) => {
      state.currentLetter = letter;
    },
    logUser: (state, user) => {
      state.loggedUser = user;
    },
    changeMatchStatus: (state, status) => {
      state.matchStatus = status;
    },
  },
  getters: {
    getLobby: (state) => {
      return state.currentLobby;
    },
    getMatchStatus: (state) => {
      return state.matchStatus;
    },
    getUser: (state) => {
      return state.loggedUser;
    },
    getLetter: (state) => {
      return state.currentLetter;
    },
    getTunnel: (state) => {
      return state.ngrok;
    },
  },
});
