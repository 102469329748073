<template>
  <div class="secondaryLobbyDiv">
    <div class="mainLobbyDiv">
      <vue-notification-list class="notification" position="top-right"></vue-notification-list>
      <span class="titolo">Sgamba<span class="titolo2">reo</span></span>
      <base-lobby position="inLobby"></base-lobby>
      <div class="bottomDiv">
        <base-button size="small" v-if="this.$route.params.lobbyCode === this.$route.params.lobbyOwner" @click="startMatch">START MATCH</base-button>
        <base-button size="small" @click="logout()">LOGOUT</base-button>
      </div>
    </div>
    <div class="joinFriends">
      <h3>Would you like to join your friends?</h3>
      <input type="text" v-model="friendId" />
      <base-button size="small" @click="join(friendId)">JOIN!</base-button>
    </div>
  </div>
</template>
<script>
import { useNotificationStore } from "@dafcoe/vue-notification"

export default {
  name: "gameLobby",
  data() {
    return {
      friendId: "",
      channel: null,
    }
  },
  created() {
    this.join(this.$route.params.lobbyCode)
  },
  methods: {
    redirectToGame() {
      //in questo caso il lobbyOwner non è veramente l'owner della lobby
      this.$router.push("/main/" + this.$route.params.lobbyOwner + "/" + this.$route.params.lobbyCode)
    },
    startMatch() {
      this.axios.post(`${this.$store.getters.getTunnel}/api/createAlphabetMatchTable/${this.$route.params.lobbyOwner}`).then(() => {
        this.axios.post(`${this.$store.getters.getTunnel}/api/startForEverybody`).then(() => {
          this.$router.push("/main/" + this.$route.params.lobbyOwner + "/" + this.$route.params.lobbyCode)
        })
      })
    },
    logout() {
      this.axios.post(`${this.$store.getters.getTunnel}/api/auth/logout`).then((response) => {
        console.log(response)
        localStorage.setItem("bearerToken", "")
        this.$router.push("/")
      })
    },
    join(lobbyId) {
      const channelName = `lobby${lobbyId}`
      // this.channel = this.echo.private(channelName)

      this.channel = this.echo.channel(channelName)

      // const joinedChannel = this.echo.join(channelName)
      // console.log(joinedChannel)
      // joinedChannel.here((users) => {
      //   console.log(`Current users in ${channelName}:`, users)
      // })

      // joinedChannel.joining((user) => {
      //   console.log(`${user.name} joined ${channelName}`)
      // })

      // joinedChannel.leaving((user) => {
      //   console.log(`${user.name} left ${channelName}`)
      // })
      this.channel.listen(".tmp-lobby-event", (e) => {
        // this.$store.commit("setLobby", e)
        this.$store.state.currentLobby = e.users
      })
      this.channel.listen(".start-match-event", () => {
        this.redirectToGame()
      })
      this.axios.post(`${this.$store.getters.getTunnel}/api/lobby/${lobbyId}`).then((response) => {
        if (response.data != 0) {
          //this.$store.commit("setLobby", response.data)
          this.$store.state.currentLobby = response.data
          this.$router.push(`/lobby/${this.$route.params.lobbyOwner}/${lobbyId}`)
        } else {
          const { setNotification } = useNotificationStore()
          const registeredNotification = {
            message: "The user is offline",
            type: "alert",
            showIcon: true,
            dismiss: {
              manually: true,
              automatically: true,
            },
            duration: 10000,
            showDurationProgress: true,
            appearance: "glass",
          }
          setNotification(registeredNotification)
        }
      })
    },
  },
}
</script>
<style scoped>
.mainLobbyDiv {
  width: 50%;
  height: 100vh;
  background-color: rgb(255, 166, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.secondaryLobbyDiv {
  width: 100%;
  height: 100vh;
  display: flex;
}
.joinFriends {
  background-color: rgb(255, 166, 0);
  width: 50%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.joinFriends input {
  width: 40%;
  padding: 5px;
  margin: 20px;
}
.joinFriends h3 {
  color: var(--marrone);
}

.bottomDiv {
  width: 50%;
  height: 30%;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
.titolo {
  margin-top: 15px;
  font-weight: 670;
  font-size: 68px;
  color: var(--marrone);
}
.titolo2 {
  color: var(--giallo-chiaro);
}
</style>
