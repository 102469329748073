import axios from "axios";

const ax = axios.create()
ax.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("bearerToken")
    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `${token}`,
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)
export { ax }
